<template>
    <div class="global-header" v-if="data">
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <a class="logo" href="/">
                        <NuxtImg provider="kontentAiProvider" class="img-fluid" :src="logo" v-if="logo" />
                        <NuxtImg provider="kontentAiProvider" class="img-fluid" src="@/assets/img/Logo_A4-Learn_BW.png" v-else />
                    </a>
                </div>
                <div class="col-sm">
                    <CallToAction custom-class="btn btn-outline-primary btn-sm" v-for="cta in data.ctas" :model="cta" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { type CallToActionModel, type HeaderModel } from '~/models';

const siteStore = useSiteStore();
const pageStore = usePageStore();

const cfg = siteStore.siteConfig;

const model = cfg.elements.header.value.length > 0 ? siteStore.getLinkedItem<HeaderModel>(cfg.elements.header.value[0]):undefined;

const data = computed(() => {
    if (model){

        return {
            legalText: model?.elements?.legalText?.value,
            logo: firstOrDefault(model?.elements?.logo?.value),
            ctas: siteStore.getListAs<CallToActionModel>(model?.elements?.callToAction?.value),
        };
    }

    return undefined;
})

const logo = computed(() => {
    if (data.value?.logo?.url){
        return data.value.logo.url
    }

    return '';
});
</script>